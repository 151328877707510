import React from 'react';
import Footers from '../components/footer';
import Headers from '../components/headers';
import Helmetz from '../components/helmet';

export default function CV(){
    return(
        <main className=" bg-slate-300">
            <Helmetz />
            <Headers />
            <h1 className="mt-16 pt-4 text-center lg:text-5xl text-4xl font-bold" style={{fontFamily: 'Amatic SC', fontSize: '150'}}>
                My CV
            </h1>
            <div className="my-12 mx-auto ">
                <iframe className='mx-auto w-10/12 h-screen lg:w-1/2 lg:h-screen '
                src="https://drive.google.com/file/d/1MJMEyWKbE5Y5G2Yc2KKcPxZC88Se4QUF/preview" 
                />
            </div>
            <Footers />
        </main>
    )
}